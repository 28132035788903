import { styled } from '@mui/joy';
import { theme } from '../theme.ts';

export const FormGrid = styled('div')`
  display: grid;
  gap: 0.75rem 1rem;
  grid-template-columns: minmax(0, 1fr);
  align-items: start;

  ${theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const WideFormControlContainer = styled('div')`
  grid-column: 1;

  ${theme.breakpoints.up('sm')} {
    grid-column: 1 / span 2;
  }
`;
