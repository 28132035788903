import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import { AxiosError } from 'axios';
import isEmail from 'is-email';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRetryEmailVerification } from '../api/generated/registration-resource/registration-resource.ts';
import useRequiredParams from '../hooks/useRequiredParams.ts';

export function ChangeEmailModal({
  open,
  onClose,
  onEmailChanged,
  initialEmail,
}: {
  open: boolean;
  onClose: () => void;
  onEmailChanged: () => void;
  initialEmail: string;
}) {
  const { registrationId } = useRequiredParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({ defaultValues: { email: initialEmail } });

  useEffect(() => {
    if (open) {
      reset({ email: initialEmail }); // Reset form state (email) when re-opening modal
    }
  }, [open, reset, initialEmail]);

  const retryEmailVerificationMutation = useRetryEmailVerification({
    mutation: {
      onSuccess: (_data, variables) => {
        toast.success(
          `Wir haben Ihnen einen neuen Code an ${variables.data.email} zugestellt. Bitte überprüfen Sie Ihren Posteingang.`,
        );
        onEmailChanged();
      },
      onError: (error) => {
        if (error instanceof AxiosError && error.response?.status === 403) {
          toast.error(
            'Sie haben das Limit von möglichen Versuchen erreicht. Bitte starten Sie eine neue Registrierung.',
          );
        } else {
          toast.error('Das Ändern der E-Mail-Adresse hat nicht geklappt. Bitte versuchen Sie es später noch einmal.');
        }
      },
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        aria-labelledby="basic-modal-dialog-title"
        aria-describedby="basic-modal-dialog-description"
        sx={{ maxWidth: 500 }}>
        <Typography level="title-md" component="h2">
          E-Mail-Adresse ändern
        </Typography>
        <form
          noValidate
          onSubmit={handleSubmit(({ email }) =>
            retryEmailVerificationMutation.mutate({ data: { email }, registrationId }),
          )}>
          <FormControl error={!!errors.email}>
            <FormLabel>Neue E-Mail-Adresse</FormLabel>
            <Stack rowGap={1} direction={{ md: 'row' }}>
              <Input
                type="email"
                placeholder="max.muster@example.com"
                {...register('email', { required: true, validate: (email) => isEmail(email) })}
                color="primary"
                sx={{
                  borderTopRightRadius: { md: 0 },
                  borderBottomRightRadius: { md: 0 },
                  borderRightWidth: { md: 0 },
                }}
              />
              <Button
                type="submit"
                color="primary"
                loading={retryEmailVerificationMutation.isPending}
                sx={{ borderTopLeftRadius: { md: 0 }, borderBottomLeftRadius: { md: 0 }, flexShrink: { md: 0 } }}>
                E-Mail ändern
              </Button>
            </Stack>
            {errors.email && (
              <FormHelperText>
                {errors.email.type === 'required' ? 'E-Mail muss ausgefüllt werden' : 'Ungültiges E-Mail-Format'}
              </FormHelperText>
            )}
          </FormControl>
        </form>
        <ModalClose />
      </ModalDialog>
    </Modal>
  );
}
