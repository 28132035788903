import { styled } from '@mui/joy';
import { ReactNode } from 'react';

export function ContentSection({ className, children }: { className?: string; children: ReactNode }) {
  return (
    <ContentWrapper className={className}>
      <div>{children}</div>
    </ContentWrapper>
  );
}

const ContentWrapper = styled('section')`
  > div {
    box-sizing: border-box;
    max-width: 740px;
    padding: 20px;
    margin: 0 auto;
  }
`;
