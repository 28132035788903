import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import { styled } from '@mui/joy';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import { theme } from '../theme.ts';

export function ToastContainer() {
  return (
    <StyledToastContainer
      position="bottom-right"
      icon={({ type }) => toastTypeToIcon[type]}
      closeButton={false}
      closeOnClick
      newestOnTop
    />
  );
}

const toastTypeToColor = {
  info: 'neutral',
  success: 'success',
  warning: 'warning',
  error: 'danger',
  default: 'primary',
} as const;

const toastTypeToIcon = {
  info: <Info />,
  success: <CheckCircle />,
  warning: <Warning />,
  error: <Error />,
  default: null,
} as const;

const StyledToastContainer = styled(ToastifyContainer)`
  .Toastify__toast {
    background-color: ${theme.colorSchemes.light.palette.background.surface};
    border: 1px solid;
    border-radius: ${theme.radius.sm};
    font-family: ${theme.fontFamily.body};
    font-size: ${theme.fontSize.sm};
    min-height: 0;
    padding: 1rem;
    box-shadow: ${theme.shadow.lg};

    .Toastify__toast-body {
      margin: 0;
      padding: 0 6px 0 0;
      .Toastify__toast-icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .Toastify__progress-bar {
      opacity: 1;
      border-radius: 0;
    }
  }
  ${Object.entries(toastTypeToColor).map(
    ([toastType, color]) =>
      `
        .Toastify__toast.Toastify__toast--${toastType} {
          color: ${theme.colorSchemes.light.palette[color].outlinedColor};
          --Icon-color: ${theme.colorSchemes.light.palette[color].outlinedColor};
          border-color: ${theme.colorSchemes.light.palette[color].outlinedBorder};
          --toastify-color-progress-${toastType === 'default' ? 'light' : toastType}: ${theme.colorSchemes.light.palette[color].outlinedBorder};
        }
      `,
  )}
`;
