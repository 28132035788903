/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ErrorType } from '../../axios';
import { request } from '../../axios';
import type { Practice, QueryPracticesParams } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const queryPractices = (
  params: QueryPracticesParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<Practice[]>({ url: `/patient-api/practices`, method: 'GET', params, signal }, options);
};

export const getQueryPracticesQueryKey = (params: QueryPracticesParams) => {
  return [`/patient-api/practices`, ...(params ? [params] : [])] as const;
};

export const getQueryPracticesQueryOptions = <
  TData = Awaited<ReturnType<typeof queryPractices>>,
  TError = ErrorType<unknown>,
>(
  params: QueryPracticesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof queryPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQueryPracticesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof queryPractices>>> = ({ signal }) =>
    queryPractices(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof queryPractices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type QueryPracticesQueryResult = NonNullable<Awaited<ReturnType<typeof queryPractices>>>;
export type QueryPracticesQueryError = ErrorType<unknown>;

export const useQueryPractices = <TData = Awaited<ReturnType<typeof queryPractices>>, TError = ErrorType<unknown>>(
  params: QueryPracticesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof queryPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getQueryPracticesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
