import { CloudUpload } from '@mui/icons-material';
import { Button, Chip, ChipDelete, CircularProgress, Stack, styled } from '@mui/joy';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { FileDetailsDto } from '../api/generated/model';
import {
  useDeleteFileById,
  useSaveFile,
} from '../api/generated/registration-file-resource/registration-file-resource.ts';

const MAX_FILE_SIZE = 15000000;

interface Props {
  uploadedFiles: FileDetailsDto[];
  onChange: (newUploadedFiles: FileDetailsDto[]) => void;
}
export function FileUpload({ uploadedFiles, onChange }: Props) {
  const [fileUploadProgress, setFileUploadProgress] = useState<null | number>(null);
  const uploadFileMutation = useSaveFile({
    mutation: {
      onSettled: () => setFileUploadProgress(null),
      onSuccess: (data) => onChange([...uploadedFiles, data]),
      onError: () => toast.error('Beim Hochladen ist ein unerwarteter Fehler aufgetreten'),
    },
    request: {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) setFileUploadProgress(progressEvent.loaded / progressEvent.total);
      },
    },
  });

  const deleteFileMutation = useDeleteFileById({
    mutation: {
      onSuccess: (_, { id: fileIdToDelete }) =>
        onChange([...uploadedFiles.filter((file) => file.id !== fileIdToDelete)]),
      onError: () => toast.error('Beim Löschen ist ein unerwarteter Fehler aufgetreten'),
    },
  });
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files[0].size <= MAX_FILE_SIZE) {
        uploadFileMutation.mutate({
          data: {
            file: event.target.files[0],
          },
        });
      } else {
        toast.error('Die maximale Dateigrösse beträgt 15MB');
      }
      event.target.value = '';
    }
  };

  return (
    <Stack columnGap={1} rowGap={0.5} flexWrap="wrap" direction="row">
      <Button
        tabIndex={-1}
        sx={{ flexShrink: 0, alignSelf: 'start' }}
        component={'label'}
        variant={'outlined'}
        color={'neutral'}
        loading={uploadFileMutation.isPending}
        startDecorator={<CloudUpload />}
        loadingIndicator={
          fileUploadProgress ? (
            <Stack direction={'row'} gap={1}>
              <CircularProgress determinate value={fileUploadProgress * 100} />
              {Math.round(fileUploadProgress * 100)}%
            </Stack>
          ) : (
            <CircularProgress />
          )
        }>
        Datei hochladen
        <VisuallyHiddenInput
          type={'file'}
          accept={'image/jpeg, image/png, image/heic, application/pdf'}
          onChange={(event) => handleFileUpload(event)}
        />
      </Button>
      <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 5 }}>
        {uploadedFiles?.map((file) => (
          <Chip
            sx={{ maxWidth: '100%', gap: '0.5rem' }}
            size={'lg'}
            key={file.id}
            endDecorator={
              <ChipDelete
                color={'danger'}
                onClick={() =>
                  deleteFileMutation.mutate({
                    id: file.id,
                  })
                }
              />
            }>
            {file.name}
          </Chip>
        ))}
      </div>
    </Stack>
  );
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
