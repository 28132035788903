import { circularProgressClasses, extendTheme, VariantProp } from '@mui/joy';
import { ColorPaletteProp } from '@mui/joy/styles/types';

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#e8ebf0',
          100: '#c4ccdb',
          200: '#9facc3',
          300: '#7b8caa',
          400: '#5f7399',
          500: '#425b8a',
          600: '#3c5482',
          700: '#334a76',
          800: '#2d4069',
          900: '#243050',
          solidDisabledBg: 'var(--joy-palette-neutral-400)',
          solidDisabledColor: 'var(--joy-palette-neutral-50)',
        },
        ...Object.fromEntries(
          ['neutral', 'danger', 'success', 'warning'].map((color) => [
            color,
            {
              solidDisabledBg: 'var(--joy-palette-neutral-400)',
              solidDisabledColor: 'var(--joy-palette-neutral-50)',
            },
          ]),
        ),
      },
    },
  },
  components: {
    JoyInput: { styleOverrides: { root: { boxShadow: 'none' } } },
    JoySelect: { styleOverrides: { root: { boxShadow: 'none' } } },
    JoyAutocomplete: { styleOverrides: { root: { boxShadow: 'none' } } },
    JoyTextarea: { styleOverrides: { root: { boxShadow: 'none' } } },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => focusOutline(ownerState),
        loadingIndicatorCenter: ({ theme, ownerState: { color = 'neutral', variant = 'solid' } }) => ({
          [`>.${circularProgressClasses.root}`]: {
            '--CircularProgress-trackColor': 'transparent',
            '--CircularProgress-progressColor': theme.palette[color][`${variant}DisabledColor`],
          },
        }),
      },
    },
    JoyChip: {
      styleOverrides: {
        startDecorator: ({ ownerState }) => focusOutline(ownerState),
        endDecorator: ({ ownerState }) => focusOutline(ownerState),
      },
    },
  },
});

function focusOutline({ color, variant }: { color?: ColorPaletteProp; variant?: VariantProp }) {
  return { '--focus-outline-offset': color === 'primary' && variant === 'solid' ? '2px' : '0' };
}
