import { Sheet, Typography } from '@mui/joy';
import { theme } from '../theme.ts';
import { ContentSection } from './ContentSection.tsx';

export function Header() {
  return (
    <Sheet
      invertedColors
      variant="solid"
      sx={{
        bgcolor: 'primary.800',
        background: `linear-gradient(to bottom, ${theme.vars.palette.primary[800]}, ${theme.vars.palette.primary[900]})`,
      }}>
      <ContentSection>
        <Typography
          level="h2"
          component="h1"
          sx={{ fontSize: { xs: theme.vars.fontSize.xl2, sm: theme.vars.fontSize.xl3 } }}>
          Der Weg zu Ihrem Hausarzt
        </Typography>
      </ContentSection>
    </Sheet>
  );
}
