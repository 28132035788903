export const argomedData = {
  email: {
    text: 'info@hausarztmodell.ch',
    link: 'mailto:info@hausarztmodell.ch',
  },
  tel: {
    text: '+41 56 48 303 48',
    link: 'tel:+41564830348',
  },
  imprintUrl: 'https://www.hausarztmodell.ch/Impressum_new/',
  privacyPolicyUrl: 'https://www.hausarztmodell.ch/Datenschutzerklaerung/',
};
