import { captureException } from '@sentry/react';
import axios, { AxiosError, AxiosRequestConfig, isAxiosError } from 'axios';

const axiosInstance = axios.create();

// add a second `options` argument here if you want to pass extra options to each generated query
export const request = async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const response = await axiosInstance.request<T>({ ...config, ...options });
  return response.data;
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (shouldCaptureException(error)) {
      captureException(error);
    }
    return Promise.reject(error);
  },
);

const ignoredAxiosErrorCodes = [AxiosError.ERR_CANCELED, AxiosError.ERR_NETWORK, AxiosError.ECONNABORTED];

function shouldCaptureException(error: unknown) {
  if (isAxiosError(error)) {
    if (error.code && ignoredAxiosErrorCodes.includes(error.code)) {
      return false;
    }
  }
  return true;
}

export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData;
