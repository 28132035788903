import { Box, Link, Sheet, Stack, Typography, styled } from '@mui/joy';
import LogoImg from '../assets/logo/Argomed_Logo_ohne_Claim_dark_mode.svg';
import { theme } from '../theme.ts';
import { argomedData } from '../utils/argomedData.ts';
import { ContentSection } from './ContentSection.tsx';

export function Footer() {
  return (
    <footer>
      <Sheet invertedColors variant="solid" color="primary" sx={{ bgcolor: '#273343' }}>
        <ContentSection>
          <FooterGrid columnGap={2} rowGap={{ xs: 0.5, sm: 0 }}>
            <Logo src={LogoImg} alt="Argomed Logo" />
            <Address>
              <Typography>Argomed Ärzte AG</Typography>
              <Typography>Bahnhofstrasse 24</Typography>
              <Typography>CH-5600 Lenzburg</Typography>
            </Address>
            <Stack direction="row" columnGap={2} flexWrap="wrap" gridArea="links">
              <Link href={argomedData.email.link}>{argomedData.email.text}</Link>
              <Link href={argomedData.tel.link}>{argomedData.tel.text}</Link>
            </Stack>
          </FooterGrid>
        </ContentSection>
      </Sheet>
      <Sheet invertedColors variant="solid" sx={{ bgcolor: '#1a2029' }}>
        <ContentSection>
          <Stack direction="row" columnGap={2} justifyContent="space-between" flexWrap="wrap" gridArea="copyright">
            <Stack direction="row" columnGap={2} flexWrap="wrap">
              <Link href={argomedData.privacyPolicyUrl} target="_blank">
                Datenschutzerklärung
              </Link>
              <Link href={argomedData.imprintUrl} target="_blank">
                Impressum
              </Link>
            </Stack>
            <Typography>© {new Date().getFullYear()} Argomed Ärzte AG</Typography>
          </Stack>
        </ContentSection>
      </Sheet>
    </footer>
  );
}

const FooterGrid = styled(Box)`
  display: grid;
  grid-template-areas:
    'address logo'
    'links links'
    'copyright copyright';
  align-items: start;
  grid-template-columns: 1fr auto;
  ${theme.breakpoints.up('sm')} {
    grid-template-areas:
      'address logo'
      'links logo'
      'copyright copyright';
    grid-template-rows: auto 1fr;
  }
`;

const Logo = styled('img')`
  grid-area: logo;
  height: calc(2 * 24px); // 2 * line height
`;

const Address = styled(Typography)`
  grid-area: address;
  font-style: normal;
  ${theme.breakpoints.up('sm')} {
    display: flex;
    > *:not(:first-of-type) {
      border-image: linear-gradient(to bottom, transparent, currentColor, transparent) 0 0 0 1;
      border-left: solid 1px;
      padding-left: 1ch;
    }
    > *:not(:last-of-type) {
      padding-right: 1ch;
    }
  }
`.withComponent('address');
