import { Input, inputClasses, InputProps, styled } from '@mui/joy';
import { ChangeEventHandler, forwardRef, useState } from 'react';
import { theme } from '../theme.ts';

export const DateInput = forwardRef<HTMLDivElement, InputProps>(function DateInput(props, ref) {
  // cache whether the field has a value set (react-hook-form does not pass a value to decrease latency)
  const [hasValueCached, setHasValueCached] = useState(false);

  const onChange: ChangeEventHandler<HTMLInputElement> = function (this: unknown, event) {
    setHasValueCached(!!event.target.value);
    if (props.onChange) {
      props.onChange.call(this, event);
    }
  };

  const hasValue = typeof props.value !== 'undefined' ? !!props.value : hasValueCached;

  // Pass the ref down to StyledDateInput
  return <StyledDateInput type="date" ref={ref} {...props} onChange={onChange} hasValue={hasValue} />;
});

const StyledDateInput = styled(Input, { shouldForwardProp: (propName) => propName !== 'hasValue' })<{
  hasValue: boolean;
}>`
  .${inputClasses.input} {
    &:not(:focus-within) {
      opacity: ${({ hasValue }) => !hasValue && `var(--Input-placeholderOpacity)`};
    }
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-year-field {
      border-radius: 2px;
      &:focus {
        background: ${theme.palette.focusVisible};
      }
    }
  }
`;
