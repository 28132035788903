import {
  Alert,
  Button,
  Card,
  CardActions,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
} from '@mui/joy';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Failure, FailureReason, VerifyEmail200 } from '../api/generated/model';
import {
  getGetLatestEmailVerificationQueryKey,
  useGetLatestEmailVerification,
  useRetryEmailVerification,
  useVerifyEmail,
} from '../api/generated/registration-resource/registration-resource.ts';
import { ChangeEmailModal } from '../components/ChangeEmailModal.tsx';
import { ContentSection } from '../components/ContentSection.tsx';
import { FormGrid, WideFormControlContainer } from '../components/FormGrid.tsx';
import useRequiredParams from '../hooks/useRequiredParams.ts';

export function VerifyEmail() {
  const { registrationId } = useRequiredParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: emailVerification, isPending, isError } = useGetLatestEmailVerification(registrationId);
  const invalidateLatestEmailVerificationQuery = () =>
    queryClient.invalidateQueries({ queryKey: getGetLatestEmailVerificationQueryKey(registrationId) });
  const [isChangeEmailOpen, setIsChangeEmailOpen] = useState(false);

  return (
    <ContentSection>
      <Stack gap={1}>
        <Typography level="h3" component="h2">
          E-Mail-Verifizierung
        </Typography>
        {isPending ? (
          <CircularProgress />
        ) : isError ? (
          <Alert color="danger">
            Leider kann die E-Mail-Verifizierung momentan nicht geladen werden. Bitte versuchen Sie es in ein paar
            Minuten erneut.
          </Alert>
        ) : (
          <Stack gap={2}>
            <VerificationCodeForm
              onVerified={() => navigate(`/registration/${registrationId}/verify-email-succeeded`, { replace: true })}
              onRetry={() => invalidateLatestEmailVerificationQuery()}
              email={emailVerification.email}
            />
            <Typography>
              Zur E-Mail-Verifizierung wurde Ihnen ein 6-stelliger Code per E-Mail an {emailVerification.email}{' '}
              zugesendet. Dies kann einige Minuten dauern. Bitte schauen Sie ebenfalls im Spamordner nach.
            </Typography>
            <FormGrid>
              <Card sx={{ rowGap: 0.5 }}>
                <Typography level="title-md">Keine E-Mail erhalten?</Typography>
                <Typography level="body-md">E-Mail-Adresse ist nicht korrekt?</Typography>
                <CardActions sx={{ pt: 0.5 }}>
                  <Button onClick={() => setIsChangeEmailOpen(true)}>Hier E-Mail-Adresse ändern</Button>
                </CardActions>
              </Card>
            </FormGrid>
            <ChangeEmailModal
              open={isChangeEmailOpen}
              onClose={() => setIsChangeEmailOpen(false)}
              onEmailChanged={() => {
                setIsChangeEmailOpen(false);
                void invalidateLatestEmailVerificationQuery();
              }}
              initialEmail={emailVerification.email}
            />
          </Stack>
        )}
      </Stack>
    </ContentSection>
  );
}

function VerificationCodeForm({
  onVerified,
  onRetry,
  email,
}: {
  onVerified: () => void;
  onRetry: () => void;
  email: string;
}) {
  const { registrationId } = useRequiredParams();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{ code: string }>({ defaultValues: { code: '' } });

  const verifyEmailMutation = useVerifyEmail();
  const retryEmailVerification = useRetryEmailVerification({
    mutation: {
      onSuccess: () => {
        onRetry();
        verifyEmailMutation.reset();
        toast.success(
          `Wir haben Ihnen einen neuen Code an ${email} zugestellt. Bitte überprüfen Sie Ihren Posteingang.`,
        );
      },
      onError: (error) => {
        if (error instanceof AxiosError && error.response?.status === 403) {
          toast.error(
            'Sie haben das Limit von möglichen Versuchen erreicht. Bitte starten Sie eine neue Registrierung.',
          );
        } else {
          toast.error('Das Senden eines neuen Codes hat nicht geklappt. Bitte versuchen Sie es später noch einmal.');
        }
      },
    },
  });

  return (
    <form
      onSubmit={handleSubmit(({ code }) => {
        verifyEmailMutation.mutate(
          { registrationId, data: { code } },
          {
            onSuccess: (data) => {
              if (data.successful) {
                onVerified();
              }
            },
            onError: () => toast.error('Beim Verifizieren ist ein unerwarteter Fehler aufgetreten'),
          },
        );
      })}>
      <FormGrid>
        <WideFormControlContainer>
          <FormControl error={!!errors.code}>
            <FormLabel>6-stelligen Code eingeben</FormLabel>
            <Stack direction="row">
              <Input
                color="primary"
                placeholder="123456"
                autoComplete="off"
                {...register('code', { required: true, pattern: /^[0-9]{6}$/ })}
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0 }}
              />
              <Button
                type="submit"
                variant="solid"
                color="primary"
                loading={verifyEmailMutation.isPending}
                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, flexShrink: 0 }}>
                ok
              </Button>
            </Stack>
            {errors.code && (
              <FormHelperText>
                {errors.code.type === 'required'
                  ? 'Code muss ausgefüllt werden'
                  : /^[0-9]*$/.test(getValues().code)
                    ? 'Der Code sollte aus exakt 6 Ziffern bestehen'
                    : 'Der Code besteht ausschliesslich aus Ziffern'}
              </FormHelperText>
            )}
            <FormHelperText>
              <Typography level="body-sm" textColor="neutral.700">
                Geben Sie hier den 6-stelligen Code ein, den wir Ihnen per E-Mail gesendet haben.
              </Typography>
            </FormHelperText>
          </FormControl>
        </WideFormControlContainer>
        {isVerifyEmailDataFailure(verifyEmailMutation.data) && (
          <WideFormControlContainer>
            <Alert
              color="danger"
              endDecorator={
                (verifyEmailMutation.data.reason === FailureReason.CODE_INVALID ||
                  verifyEmailMutation.data.reason === FailureReason.CODE_EXPIRED) && (
                  <Button
                    color="danger"
                    loading={retryEmailVerification.isPending}
                    onClick={() => retryEmailVerification.mutate({ registrationId, data: { email } })}>
                    Neuen Code senden
                  </Button>
                )
              }>
              {verifyEmailMutation.data.reason === FailureReason.NOT_FOUND
                ? 'Diese Registrierung wurde nicht gefunden. Bitte starten Sie eine neue Registrierung.'
                : verifyEmailMutation.data.reason === FailureReason.CODE_INVALID
                  ? 'Dieser Code ist nicht korrekt. Bitte überprüfen Sie den Code.'
                  : verifyEmailMutation.data.reason === FailureReason.TOO_MANY_ATTEMPTS
                    ? 'Sie haben das Limit von möglichen Versuchen erreicht. Bitte starten Sie eine neue Registrierung.'
                    : 'Dieser Code ist abgelaufen. Bitte senden Sie sich einen neuen Code zu.'}
            </Alert>
          </WideFormControlContainer>
        )}
      </FormGrid>
    </form>
  );
}

// type guard because autogenerated types are not a discriminated union
function isVerifyEmailDataFailure(verifyEmailData?: VerifyEmail200): verifyEmailData is Failure {
  return !!verifyEmailData && !verifyEmailData.successful;
}
