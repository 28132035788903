import { useParams } from 'react-router-dom';

export default function useRequiredParams() {
  const params = useParams();
  return new Proxy(params, {
    get(target, paramName) {
      if (paramName in target && typeof paramName === 'string') {
        const param = target[paramName];
        if (param) {
          return param;
        }
      }
      throw new Error(`param ${paramName.toString()} not found`);
    },
  }) as Record<string, string>;
}
