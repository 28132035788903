import { browserTracingIntegration, init, makeBrowserOfflineTransport, makeFetchTransport } from '@sentry/react';
import { environment } from './utils/environment';

export const setupSentry = () => {
  init({
    dsn: environment === 'localhost' ? '' : `${import.meta.env.VITE_SENTRY_DSN}`,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.1,
    autoSessionTracking: false,
    environment,
    release: `${import.meta.env.VITE_APP_COMMIT_ID}`,
    normalizeDepth: 10,
  });
};
