import { styled } from '@mui/joy';
import { Route, Routes } from 'react-router-dom';
import { Footer } from './components/Footer.tsx';
import { Header } from './components/Header.tsx';
import { Register } from './pages/Register.tsx';
import { VerifyEmail } from './pages/VerifyEmail.tsx';
import { VerifyEmailSucceeded } from './pages/VerifyEmailSucceeded.tsx';

export function App() {
  return (
    <Wrapper>
      <Header />
      <Routes>
        <Route path={'/'} element={<Register />} />
        <Route path={'/registration/:registrationId/verify-email'} element={<VerifyEmail />} />
        <Route path={'/registration/:registrationId/verify-email-succeeded'} element={<VerifyEmailSucceeded />} />
      </Routes>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled('div')`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;
