import { Stack, styled, Typography } from '@mui/joy';
import {
  getGetImageByNameQueryKey,
  useGetPublicTemplates,
} from '../api/generated/public-template-resource/public-template-resource.ts';
import { ContentSection } from '../components/ContentSection.tsx';

export function VerifyEmailSucceeded() {
  const templates = useGetPublicTemplates();

  return (
    <ContentSection>
      <Stack gap={3}>
        <StyledImage
          src={`${getGetImageByNameQueryKey({ imageName: 'SUCCESSFUL_SUBMISSION_IMAGE' })[0]}?${new URLSearchParams({ imageName: 'SUCCESSFUL_SUBMISSION_IMAGE' }).toString()}`}
          alt="Registrierung erfolgreich"
        />
        <Typography level="body-lg">{templates.data?.registrationSubmitted}</Typography>
      </Stack>
    </ContentSection>
  );
}

const StyledImage = styled('img')`
  max-width: 600px;
  align-self: center;
`;
