/**
 * HAM API – Generated by orval 🍺
 * Do not edit manually.
 */

export type FailureReason = (typeof FailureReason)[keyof typeof FailureReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FailureReason = {
  NOT_FOUND: 'NOT_FOUND',
  CODE_EXPIRED: 'CODE_EXPIRED',
  CODE_INVALID: 'CODE_INVALID',
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
} as const;
